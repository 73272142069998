<template>
  <div class="login">
    <div class="content">
      <div class="title-content">
        <div class="title">登录</div>
        <div class="subtitle">以访问您的帐户</div>
      </div>
      <div class="form-content">
        <Form
          class="form"
          :model="form"
          :rules="rule">
          <FormItem prop="user">
            <div class="name">账户</div>
            <Input class="input" type="text" v-model="form.mobile" placeholder="手机号">
            </Input>
          </FormItem>
          <FormItem prop="password">
            <div class="name">密码</div>
            <Input class="input" type="password" v-model="form.password" placeholder="密码">
            </Input>
          </FormItem>
          <FormItem>
            <div class="actions">
              <Button class="login-button" type="primary" @click="onSubmit">确认</Button>
            </div>
          </FormItem>
        </Form>
      </div>
      <div class="block">
        <div class="title">
          欢迎使用
        </div>
        <div class="subtitle">
          快视科技{{ USER_TYPE_TEXT[userType] }}平台
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LOGIN_TYPE, USER_TYPE, USER_TYPE_TEXT } from './constants';
import LoginApi from './login.api';

const { VUE_APP_MOBILE = '', VUE_APP_PASSWD = '', VUE_APP_LOGIN_TYPE } = process.env || {};

export default {
  name: 'login',
  created() {
    if (VUE_APP_LOGIN_TYPE === 'DOMAIN') {
      this.userType = this.getUserTypeByOrigin();
    } else {
      this.userType = this.getUserTypeByParam();
    }
  },
  watch: {
    $route: () => {
      if (VUE_APP_LOGIN_TYPE === 'PARAM') {
        this.userType = this.getUserTypeByParam();
      }
    },
  },
  computed: {
    loginType() {
      return LOGIN_TYPE[this.userType.toUpperCase()] || LOGIN_TYPE[USER_TYPE.ADMIN];
    },
  },
  data() {
    return {
      userType: USER_TYPE.ADMIN,
      USER_TYPE_TEXT,
      form: {
        mobile: VUE_APP_MOBILE,
        password: VUE_APP_PASSWD,
      },
      rule: {},
    };
  },
  methods: {
    getUserTypeByOrigin() {
      const { hostname } = window.location;
      let userType = USER_TYPE.ADMIN;

      if (hostname.match(/channel/i)) {
        userType = USER_TYPE.CHANNEL;
      } else if (hostname.match(/store/i)) {
        userType = USER_TYPE.OUTLET;
      }

      return userType;
    },
    getUserTypeByParam() {
      return this.$route.params.userType;
    },
    async onSubmit() {
      const { mobile, password } = this.form;
      const {
        jwt_token: token = '',
        nick_name: nickName = '',
        store_name: storeName = '',
        channel_name: channelName = '',
        store_id: storeId = '',
        channel_id: channelId = '',
      } = await LoginApi.login(mobile, password, this.loginType);
      this.$store.commit('setToken', token);
      this.$store.commit('setNickName', nickName);
      this.$store.commit('setUserType', this.userType);
      this.$store.commit('setMenus');
      this.$store.commit('setAuthTreeLoadState', false);
      this.$store.commit('setStoreName', storeName);
      this.$store.commit('setChannelName', channelName);
      this.$store.commit('setStoreId', storeId);
      this.$store.commit('setChannelId', channelId);
      await this.$router.push('/');
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  background: #F5F6F8;
  position: relative;
  height: 100%;
  min-width: 800px;
  min-height: 400px;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 32vw; // 16:9
  width: 62vw;
  min-width: 720px;
  min-height: 400px;
  max-width: 900px;
  max-height: 490px;
}

.title-content {
  position: absolute;
  top: -146px;
  left: 0;
  width: 900px;
  margin: 50px auto 30px;
  .title {
    font-size: 30px;
    color: #2D8BFF;
  }
  .subtitle {
    color: #888;
  }
}

.form-content {
  position: absolute;
  width: 49vw;
  min-width: 590px;
  max-width: 760px;
  height: 100%;
  margin-right: 10vw;
  background: #fff;
}

.form {
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
  width: 20vw;
  min-width: 260px;
  max-width: 340px;
  .name {
    color: #888;
    padding: 5px 0;
  }
  .ivu-form-item {
    margin-bottom: 0;
  }
  /deep/ input {
    border: none;
    outline: none;
    border-radius: 3px;
    background: #F5F6F8;
    line-height: 40px;
    width: 100%;
    padding: 0 15px;
    font-weight: 800;
    color: #555;
    outline: none;
    &:focus,
    &:hover,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2D8BFF;
  color: #FFF;
  box-shadow: 0 0 50px 0 #a1caff;
  position: absolute;
  width: 19vw;
  height: 19vw;
  min-width: 190px;
  min-height: 190px;
  max-width: 240px;
  max-height: 240px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  .title {
    padding-bottom: 4px;
    font-size: 18px;
  }
  .subtitle {
    font-size: 22px;
  }
}

.actions {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-button {
  //position: absolute;
  //left: 20%;
  //bottom: -16px;
  padding: 0 50px;
  margin-top: 42px;
  background: #2D8BFF;
  border: none;
  color: #FFF;
  font-size: 14px;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 #b2d3fd;
}

</style>
